<template>
    <div v-loading.fullscreen.lock="fullscreenLoading">
        <el-table
            :data="getItems"
            :default-sort="{
                prop: 'date',
                order: 'ascending'
                // ascending
                // descending
            }"
        >
            <el-table-column
                label="Дата"
                prop="date"
                sortable
                min-width="150"
            >
                <template #default="{ row }">
                    {{ convertDate(row.date) }}
                </template>
            </el-table-column>

            <el-table-column
                label="Наименование"
                prop="name"
                sortable
                min-width="200"
            ></el-table-column>

            <el-table-column
                label="Тип"
                prop="category"
                sortable
                min-width="150"
            ></el-table-column>

            <el-table-column
                label="Загрузить"
                prop="link"
                min-width="150"
            >
                <template #default="{ row }">
                    <el-button
                        icon="el-icon-download"
                        type="primary"
                        size="small"
                        :loading="row.loading"
                        @click="fetchFile(row)"
                    >
                        Посмотреть и загрузить
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-row type="flex" justify="center" style="margin-top: 20px;">
            <el-pagination
                background
                layout="prev, pager, next"
                @current-change="(page) => {
                    currentPage = page
                }"
                :current-page="currentPage"
                :page-count="pageCount"
                :total="tableData.length"
            ></el-pagination>
        </el-row>
    </div>
</template>

<script>
import axios from 'axios'
import { ADRESS } from "@/store/actions/auth";
import moment from "moment";
import { handelerErrors } from "@/plugins/utils"

export default {
    data() {
        return {
            currentPage: 1,
            pageCount: 10,
            fullscreenLoading: false,
            tableData: [],
            activities: ''
        }
    },
    methods: {
        async fetchFile(data) {
            const tagA = document.createElement('a')
            tagA.href = data.adress
            tagA.target = '_blank'

            tagA.click()
        },

        convertDate(date) {
            return moment(date).format("DD.MM.YYYY HH:mm")
        }
    },
    computed: {
        getItems() {
            return this.tableData.slice(
                this.pageCount *
                this.currentPage -
                this.pageCount,
                this.pageCount *
                this.currentPage
            )
        },
    },
    async created() {
        try {
            this.fullscreenLoading = true
            this.activities = localStorage.getItem("user-token")

            const resp = await axios({
                url: ADRESS + 'getPriceList',
                method: 'get',
                headers: {
                    Authorization: this.activities
                }
            })

            this.tableData = resp.data.FileAddress.map(el => ({
                ...el,
                ...{ loading: false }
            }))
        }
        catch (e) {
            handelerErrors(e);
        }
        finally {
            this.fullscreenLoading = false
        }
    }
}
</script>

<style lang="scss">
    
</style>