<template>
  <div class="clients-limit">
    <el-badge :value="inProcess" class="clients-limit__process">
      <span class="clients-limit__fixed"> {{ fixed }} </span>
    </el-badge>
    <span class="clients-limit__limit"> / {{ limit }} </span>
  </div>
</template>

<script>
export default {
  name: "vClientLimit",
  data() {
    return {};
  },
  props: {
    fixed: Number,
    limit: Number,
    inProcess: Number,
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
.clients-limit {
  min-width: 80px;
  width: 80px;
  padding-left: 10px;
  display: inline;
  span {
    font-weight: bold;
    vertical-align: bottom;
  }
}
.clients-limit__process {
  vertical-align: bottom;
  .el-badge__content.is-fixed {
    top: -2px;
    right: 6px;
  }
}
</style>