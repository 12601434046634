import { USER_REQUEST, USER_ERROR, USER_SUCCESS, ADRESS, AUTH_LOGOUT } from "../actions/auth";

//import apiCall from "@/utils/api";
import Vue from "vue";
import axios from 'axios';
import { handelerErrors } from "@/plugins/utils"

const state = { status: "", profile: {}, currentPageIndex: '1' };

const getters = {
    getProfile: state => state.profile,
    isProfileLoaded: state => !!state.profile.name,
    getCurrentPageIndex: state => state.currentPageIndex
};

const actions = {
    [USER_REQUEST]: ({ commit, dispatch }) => {
        const activities = localStorage.getItem("user-token");
        commit(USER_REQUEST);
        setTimeout(() => {
            axios.get(ADRESS + 'auth', {
                    headers: { 'authorization': activities }
                })
                .then(resp => {
                    commit(USER_SUCCESS, resp.data);
                })
                .catch(() => {
                    commit(USER_ERROR);
                    handelerErrors(err);
                });
        }, 500)
    },
    setCurrentPageIndex: ({ commit }, index) => {
        console.log(index);
        commit('SetCurrentPageIndex', index);
    }

};

const mutations = {
    [USER_REQUEST]: state => {
        state.status = "loading";
    },
    [USER_SUCCESS]: (state, resp) => {
        state.status = "success";
        Vue.set(state, "profile", resp);
    },
    [USER_ERROR]: state => {
        state.status = "error";
    },
    [AUTH_LOGOUT]: state => {
        state.profile = {};
    },
    SetCurrentPageIndex: (state, index) => {
        console.log(index);
        state.currentPageIndex = index;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};