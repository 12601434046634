import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/ru-RU.js'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { MediaQueries } from 'vue-media-queries';
import store from './store'
import App from './App.vue'
import router from '@/router'


const mediaQueries = new MediaQueries();

Vue.use(mediaQueries);

Vue.use(ElementUI, { locale });
Vue.use(store)
Vue.component('v-select', vSelect)


new Vue({
    router,
    mediaQueries: mediaQueries,
    store,
    render: h => h(App)
}).$mount('#app')