<template>
  <div class="task-view-component" v-if="task !== null && taskMatching !== null">
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisibleOuter" @closed="onCloseDialog" width="96%"
      :close-on-click-modal="false">
      <div>
        <div class="task-view-info">
          <el-row :gutter="10">
            <el-col :span="14">
              <strong>Автор</strong>: <br />
              {{ taskMatching.author }}
            </el-col>
            <el-col :span="10">
              <span v-if="taskMatching.type == 't'"><strong>Сроки:</strong> <br />
                {{ taskMatching.dates }}</span><span v-else>
                <strong>Дата:</strong> <br />
                {{ taskMatching.dates }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="10" v-if="taskMatching.type == 't'">
            <el-col :span="14">
              <strong>Исполнитель:</strong> <br />
              {{ taskMatching.executor }}
            </el-col>
            <el-col :span="10">
              <strong>Статус:</strong> <br />
              {{ taskMatching.state }}
            </el-col>
          </el-row>
          <el-row class="task-view__watchers-block">
            <p v-html="taskMatching.watchers"></p>
          </el-row>
        </div>
        <p class="task-view__description" v-html="taskMatching.description"></p>
        <div class="task-view__files" v-if="taskMatching.files && taskMatching.files.length > 0">
          <p>Вложения:</p>
          <ul>
            <li v-for="(file, index) in taskMatching.files" :key="index">
              <p @click="downloadfile" v-html="file.name" />
            </li>
          </ul>
        </div>
        <el-row class="task-view__accepted" v-if="taskMatching.htmlstring && !taskMatching.canaccept">
          <p style="float: left" v-html="taskMatching.htmlstring"></p>
        </el-row>
        <el-row type="flex" justify="space-around" class="task-view__accepted">
          <el-popconfirm confirm-button-text="Да" @confirm="acceptTask(true)" cancel-button-text="Не согласовывать"
            icon="el-icon-info" icon-color="red" title="Согласовать?">
            <el-button v-if="taskMatching.type === 't' && taskMatching.canaccept" size="small" type="success"
              slot="reference"><i class="el-icon-success" /> Согласовать</el-button>
          </el-popconfirm>
          <el-popconfirm @confirm="acceptTask(false)" confirm-button-text="Да" cancel-button-text="Не отклонять"
            icon="el-icon-info" icon-color="red" title="Отклонить?">
            <el-button v-if="taskMatching.type === 't' && taskMatching.canaccept" size="small" type="danger"
              slot="reference"><i class="el-icon-error" /> Отклонить</el-button>
          </el-popconfirm>
        </el-row>
        <div class="task-view-messages">
          <el-button size="mini" class="task-view__button-last-messages" v-if="taskMatching.showlink == true"
            @click="showallmessage(taskMatching.id)">Предыдущие сообщения</el-button>
          <el-timeline>
            <el-timeline-item v-for="mes in taskMatching.messagelist" :key="mes.id" :timestamp="mes.date" placement="top">
              <el-card :class="{ unread: mes.unread }" @click.native="onClickReadMessage(mes)">
                <span class="task-view-messages__author">{{ mes.author }}</span>
                <p class="task-view-messages__mes-text" @click="onClickDownloadFromMessage" v-html="mes.text" />
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="drawer = true" type="Warning" size="small" class="task-view__button-menu"><i
            class="el-icon-menu"></i></el-button>
        <el-input class="message-text-area" type="textarea" size="small" :autosize="{ minRows: 1, maxRows: 2 }"
          placeholder="Введите сообщение" v-model="messageText">
        </el-input>
        <el-button @click="sendmessage()" type="primary" size="small" class="button-sent-message"><i
            class="el-icon-s-promotion el-icon-right"></i></el-button>
        <input style="display: none" type="file" id="file" ref="fileinput" @change="handleFileUpload" />
      </div>
    </el-dialog>
    <el-drawer size="70%" direction="ltr" :visible.sync="drawer" :with-header="false">
      <ul v-if="task" class="task-view__list-commands">
        <li v-if="taskMatching.type === 't'" @click="$refs.fileinput.click()">
          <i class="el-icon-upload" /> Загрузить файл
        </li>
        <li @click="dialogWatchers = true">
          <i class="el-icon-circle-plus-outline" /> Добавить наблюдателя
        </li>
        <li v-if="taskMatching.type === 't'" @click="dialogAgreement = true">
          <i class="el-icon-finished" /> Отправить на согласование
        </li>
        <li class="task-view__accept-task" v-if="taskMatching.type === 't' && taskMatching.canaccept"
          @click="acceptTask(true)">
          <i class="el-icon-success" /> Согласовать
        </li>
        <li class="task-view__no-accept-task" v-if="taskMatching.type === 't' && taskMatching.canaccept"
          @click="acceptTask(false)">
          <i class="el-icon-error" /> Отклонить
        </li>
      </ul>
    </el-drawer>
    <el-dialog width="100%" :fullscreen="true" title="Добавить наблюдателей" :visible.sync="dialogWatchers"
      append-to-body>
      <v-select v-model="selectedWatcher" label="name" maxHeight="300px" :options="listUsers"
        @option:selected="onSelectWatcher" placeholder="Выберите наблюдателя"></v-select>
      <div class="task-view__watcher" v-for="(user, index) in taskMatching.listusers" :key="index">
        {{ user.name }}
        <el-button v-if="user.allowdelete" size="mini" type="danger" icon="el-icon-delete-solid"
          @click="deleteWatcher(user)" />
      </div>
      <el-button :disabled="isAddedNewWatchers" type="primary" @click="saveWatchersUsers">Сохранить</el-button>
    </el-dialog>
    <el-dialog width="100%" :fullscreen="true" title="Отправить на согласование" :visible.sync="dialogAgreement"
      append-to-body>
      <v-select v-model="selectAgreement" label="name" maxHeight="300px" :options="listUsers"
        @option:selected="taskMatching.reconciling = selectAgreement.id" placeholder="Выберите согласующего"></v-select>
      <el-date-picker v-model="dateStartAgreement" type="date" format="dd.MM.yyyy" size="mini" value-format="yyyy-MM-dd"
        placeholder="Начало">
      </el-date-picker>
      <el-date-picker v-model="dateEndAgreement" type="date" format="dd.MM.yyyy" size="mini" value-format="yyyy-MM-dd"
        placeholder="Конец">
      </el-date-picker>
      <el-button :disabled="isValidFormToApproval" type="primary" @click="sendForApproval">Отправить</el-button>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapState } from "vuex";
import { ADRESS, AUTH_LOGOUT } from "@/store/actions/auth";
import downloadjs from "downloadjs";
export default {
  name: "vTaskView",
  data() {
    return {
      dialogVisibleOuter: false,
      dialogTitle: "",
      task: null,
      taskMatching: null,
      file: null,
      messageText: "",
      drawer: false,
      dialogWatchers: false,
      listUsers: [],
      selectedWatcher: null,
      listSelectedWatchers: [],
      watchers: [],
      dialogAgreement: false,
      selectAgreement: null,
      dateStartAgreement: null,
      dateEndAgreement: null,
    };
  },
  props: {
    propTask: Object,
    activities: String,
  },
  watch: {
    propTask(task) {
      if (task !== null && task.БизнесПроцесс !== 'Предварительное закрепление техники (new)') {
        this.task = task;
        this.getTaskData();
      }
    },
  },
  created() { },
  mounted() {
    this.headers = {
      "Content-Type": "multipart/form-data",
      Authorization: this.activities,
    };
    this.getListUsers();
  },
  computed: {
    isAddedNewWatchers() {
      return (
        this.taskMatching.type === "t" &&
        this.watchers.length === this.taskMatching.listusers.length
      );
    },
    isValidFormToApproval() {
      return !(
        this.selectAgreement &&
        this.dateStartAgreement &&
        this.dateEndAgreement
      );
    },
  },
  methods: {
    onCloseDialog() {
      this.$emit("closeDialog");
      this.taskMatching = null;
      console.log("Close");
    },
    getTaskData() {
      const loading = this.$loading({
        lock: true,
      });
      let task_id = this.propTask.hasOwnProperty('УИДРодителя') ? this.propTask.УИДРодителя : this.task.id
      let task_type = this.propTask.hasOwnProperty('УИДРодителя') ? 't' : this.task.type
      var address =
        ADRESS + "gettask?taskid=" + task_id + "&type=" + task_type;
      axios({
        method: "get",
        url: address,
        headers: {
          Authorization: this.activities,
        },
      })
        .then((response) => {
          this.taskMatching = response.data.value;
          this.taskMatching.id = task_id;
          this.taskMatching.type = task_type;
          this.dialogTitle = response.data.value.taskname;
          this.taskMatching.messagelist = response.data.value.messagelist;
          if (this.taskMatching.type === "t") {
            this.watchers = [...response.data.value.listusers];
          }
          loading.close();
          this.dialogVisibleOuter = true;
        })
        .catch((err) => {
          loading.close();
          console.log(err);
          this.$notify.error({
            title: "Ошибка",
            message: err,
          });
        });
    },
    readmessage(mesid) {
      this.taskMatching.messagelist.find(function (el) {
        el.unread = false;
        return el.id === mesid;
      });
    },
    addmessage(mes) {
      this.taskMatching.messagelist.push(mes);
    },
    readError(e) {
      if (e.response.status == 403) {
        this.$store.dispatch(AUTH_LOGOUT)
      }
    },
    sendmessage(el) {
      const loading = this.$loading({
        lock: true,
      });
      if (this.messageText.length > 0) {
        var message = {
          idtask: this.taskMatching.id,
          type: this.taskMatching.type,
          mes: this.messageText,
        };
        axios({
          method: "post",
          url: ADRESS + "sendmessage",
          data: message,
          params: {},
          headers: {
            Authorization: this.activities,
          },
        })
          .then((response) => {
            this.messageText = "";
            this.addmessage(response.data.value);
            loading.close();
          })
          .catch((err) => {
            console.log(err);
            loading.close();
            this.$notify.error({
              title: "Ошибка",
              message: err,
            });
          });
      }
    },
    onClickDownloadFromMessage(event) {
      const headers = {
        Authorization: "JWT fefege",
      };
      if (event.target.tagName == "A") {
        var str = event.target.href;
        if (str.indexOf("file") == 0) {
          axios
            .post(ADRESS + "clickhandling", str.slice(5), { headers: headers })
            .then((response) =>
              downloadjs(response.data.value.body, response.data.value.name)
            );
        }
      }
    },
    onClickReadMessage(message) {
      console.log(message);
      if (message.unread) {
        axios({
          method: "get",
          url: ADRESS + "readmessage",
          params: { mesid: message.id },
          headers: { Authorization: this.activities },
        }).then((response) => this.readmessage(message.id));
      }
    },
    showallmessage(taskid) {
      this.taskMatching.messagelist = [];
      this.taskMatching.messagelist = this.taskMatching.allmessagelist.map((a) =>
        Object.assign({}, a)
      );
      this.taskMatching.showlink = false;
    },
    downloadfile(event) {
      const loading = this.$loading({
        lock: true,
      });
      const headers = {
        Authorization: "JWT fefege",
      };

      var str = event.target.href;
      if (str.indexOf("file") == 0) {
        axios
          .post(ADRESS + "clickhandling", str.slice(5), { headers: headers })
          .then((response) => {
            downloadjs(response.data.value.body, response.data.value.name);
            loading.close();
          })
          .catch((err) => {
            console.log(err);
            loading.close();
            this.$notify.error({
              title: "Ошибка",
              message: err,
            });
          });
      }
    },
    handleFileUpload() {
      const loading = this.$loading({
        lock: true,
      });
      this.file = this.$refs.fileinput.files[0];
      let formData = new FormData();
      formData.append("file", this.file);
      axios
        .post(ADRESS + "uploadfilemessage?taskid=" + this.taskMatching.id, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: this.activities,
          },
        })
        .then((response) => {
          this.taskMatching.files = response.data.value.files;
          this.taskMatching.messagelist = response.data.value.messagelist;
          this.drawer = false;
          loading.close();
        })
        .catch((err) => {
          loading.close();
          console.log(err);
          this.$notify.error({
            title: "Ошибка",
            message: err,
          });
        });
    },
    saveWatchersUsers() {
      const loading = this.$loading({
        lock: true,
      });
      axios({
        method: "post",
        url: ADRESS + "addwatchers",
        data: this.taskMatching.listusers,
        params: { taskid: this.taskMatching.id, type: this.taskMatching.type },
        headers: {
          Authorization: this.activities,
        },
      })
        .then((response) => {
          this.taskMatching.watchers = response.data.value.htmlstring;
          this.taskMatching.listusers = response.data.value.listusers;
          this.taskMatching.messagelist = response.data.value.messagelist;
          this.dialogWatchers = false;
          this.drawer = false;
          loading.close();
        })
        .catch((err) => {
          console.log(err);
          loading.close();
          this.$notify.error({
            title: "Ошибка",
            message: err,
          });
        });
    },
    getListUsers() {
      axios({
        method: "get",
        url: ADRESS + "getlistusers",
        headers: { Authorization: this.activities },
      }).then((response) => {
        this.listUsers = response.data.value.listusers.reduce((o, i) => {
          if (!o.find((v) => v.id == i.id)) {
            o.push(i);
          }
          return o;
        }, []);
      });
    },
    filterMethod(query, item) {
      return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
    },
    onSelectWatcher() {
      this.taskMatching.listusers.push(this.selectedWatcher);
      this.selectedWatcher = null;
    },
    deleteWatcher(delUser) {
      this.taskMatching.listusers = this.taskMatching.listusers.filter(
        (user) => user.id !== delUser.id
      );
      this.selectedWatcher = null;
    },
    acceptTask(isAccepted) {
      const loading = this.$loading({
        lock: true,
      });
      axios({
        method: "post",
        url: ADRESS + "accepttask",
        params: {
          taskid: this.taskMatching.id,
          accept: isAccepted,
          idtaskaccept: this.taskMatching.idtaskaccept,
        },
        headers: {
          Authorization: this.activities,
        },
      })
        .then((response) => {
          this.taskMatching.messagelist = response.data.value.messagelist;
          this.taskMatching.htmlstring = response.data.value.htmlstring;
          this.dialogWatchers = false;
          this.drawer = false;
          loading.close();
          this.taskMatching.canaccept = null;
        })
        .catch((err) => {
          console.log(err);
          loading.close();
          this.$notify.error({
            title: "Ошибка",
            message: err,
          });
        });
    },
    sendForApproval() {
      const loading = this.$loading({
        lock: true,
      });
      axios({
        method: "post",
        url: ADRESS + "sendforapproval",
        data: this.taskMatching,
        params: { taskid: this.taskMatching.id },
        headers: {
          Authorization: this.activities,
        },
      })
        .then((response) => {
          this.taskMatching.htmlstring = response.data.value.htmlstring;
          this.taskMatching.listusers = response.data.value.listusers;
          this.taskMatching.messagelist = response.data.value.messagelist;
          this.taskMatching.seen = false;
          this.dialogAgreement = false;
          this.drawer = false;
          this.taskMatching.reconciling = null;
          loading.close();
        })
        .catch((err) => {
          console.log(err);
          loading.close();
          this.taskMatching.reconciling = null;
          this.$notify.error({
            title: "Ошибка",
            message: err,
          });
        });
    },
  },
};
</script>

<style lang="scss">
.task-view-component {
  .el-dialog__title {
    @media (max-width: 550px) {
      font-size: 15px;
      line-height: 18px;
    }
  }

  .el-dialog__header {
    padding: 20px 33px 10px 15px;
  }

  .el-dialog__body {
    padding: 0 20px;
    word-break: break-word;
  }

  .el-dialog__footer {
    padding: 15px 5px;
    position: sticky;
    bottom: 0;
    background-color: #fff;

    .dialog-footer {
      text-align: left;
      display: flex;

      .message-text-area {
        width: 100%;
        margin: 0 4px;
      }
    }
  }

  .task-view-info {
    font-size: 12px;
    word-break: break-word;
    color: #000;

    strong {
      color: #606266;
    }

    .el-row {
      margin: 7px 0;
    }
  }

  .el-icon-upload {
    cursor: pointer;
  }

  .task-view__list-commands {
    margin-top: 30px;

    li {
      font-weight: bold;
      margin: 10px 0;
      padding: 10px 10px;
      border-bottom: 1px solid #dcdfe6;
      cursor: pointer;

      @media (max-width: 550px) {
        font-size: 13px;
        line-height: 16px;
      }
    }
  }

  .task-view__accept-task {
    color: #139a43;
  }

  .task-view__no-accept-task {
    color: #f51720;
  }

  .task-view__description {
    font-size: 12px;
    background-color: #f5f5f5;
    margin: 15px -20px 0;
    color: #000;
    padding: 20px;
  }

  .task-view__watchers-block {
    strong {
      display: block;
    }
  }

  .task-view__files {
    font-size: 12px;
    background-color: #fcf7e9;
    margin: 0 -20px;
    padding: 20px;

    >p {
      font-size: 12px;
      color: #000;
      font-weight: bold;
    }

    ul {
      list-style-type: none;

      li {
        margin: 5px 0;
        display: block;
      }

      a {
        color: #139a43;
        padding: 4px;
        font-weight: 600;
      }
    }
  }

  .task-view__accepted {
    margin-top: 15px;
  }

  .task-view-messages {
    margin-top: 15px;

    .el-timeline {
      margin: 15px -7px 0;
    }

    .el-timeline-item__wrapper {
      padding-left: 16px;
    }

    .el-timeline-item__timestamp {
      @media (max-width: 550px) {
        font-size: 11px;
      }
    }

    .unread {
      border-left: 4px solid #343a40;
      cursor: pointer;

      .el-card__body {
        padding-left: 8px;
      }
    }

    .el-card__body {
      padding: 20px;

      @media (max-width: 550px) {
        padding: 12px;
      }
    }

    .task-view-messages__author {
      font-size: 13px;
      line-height: 16px;
      font-weight: bold;
      color: #000;
    }

    .task-view-messages__mes-text {
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
    }
  }
}

.task-view__watcher {
  margin: 5px 0;
  height: 28px;
}
</style>