var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading.fullscreen.lock",value:(_vm.fullscreenLoading),expression:"fullscreenLoading",modifiers:{"fullscreen":true,"lock":true}}],staticClass:"list-requests"},[(_vm.requests !== null)?_c('div',{staticClass:"list-requests-container"},[_c('el-row',{staticClass:"list-requests__search",attrs:{"type":"flex"}},[_c('el-input',{attrs:{"size":"mini","placeholder":"Поиск"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('el-button',{attrs:{"size":"mini","type":"primary","icon":"el-icon-search"}})],1),_c('el-table',{staticClass:"list-requests__table",staticStyle:{"width":"100%"},attrs:{"row-class-name":_vm.classForRow,"data":_vm.requests.filter(
        (data) =>
          data['nameCompany'] !== null &&
          (!_vm.search ||
            data['nameCompany']
              .toLowerCase()
              .includes(_vm.search.toLowerCase()))
      ),"default-sort":{ prop: 'date', order: 'descending' },"stripe":""}},[_c('el-table-column',{attrs:{"prop":"date","width":"150","sortable":"","sort-method":_vm.sortDate,"label":"Дата"}}),_c('el-table-column',{attrs:{"label":"Компания","min-width":"130","prop":"nameCompany"}}),_c('el-table-column',{attrs:{"label":"Статус","min-width":"95","prop":"status"}}),_c('el-table-column',{attrs:{"label":"Комментарий","min-width":"200","prop":"comment"}}),_c('el-table-column',{attrs:{"label":"ИНН","min-width":"200","prop":"inn"}}),_c('el-table-column',{attrs:{"label":"КПП","min-width":"200","prop":"kpp"}}),_c('el-table-column',{attrs:{"label":"Причина закрепления","min-width":"200","prop":"reason"}})],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }