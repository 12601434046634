<template>
  <div class="feedback-component">
    <el-button style="margin-left: 10px;" type="warning" icon="el-icon-bell" size="small"
      @click="dialogVisible = true">Сообщить об
      ошибки</el-button>
    <el-dialog title="Обратная связь" :visible.sync="dialogVisible" @closed="dialogVisible = false" width="96%"
      :close-on-click-modal="false">
      <el-input type="textarea" size="mini" :autosize="{ minRows: 4, maxRows: 6 }" placeholder="Содержание обращения"
        v-model="content" />
      <el-row style="margin-top: 15px;">
        <el-button @click="dialogVisible = false">Закрыть</el-button>
        <el-button :disabled="content.length < 10" type="success" size="medium" icon="el-icon-s-promotion"
          @click="sendFeedBack">Отправить</el-button>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "vFeedBack",
  data() {
    return {
      dialogVisible: false,
      content: ""
    };
  },
  props: {
    obj: Object,
    baseUrl: {
      type: String,
      default: ""
    }
  },
  watch: {

  },
  created() { },
  mounted() {

  },
  computed: {

  },
  filters: {

  },
  methods: {
    sendFeedBack() {
      const loading = this.$loading({
        lock: true,
        spinner: "el-icon-loading",
      });
      const headers = {
        "content-type": "application/json",
      };
      const data = {
        obj: this.obj,
        content: this.content
      }
      axios
        .post(`${this.baseUrl}/api/send-feedback/`, data, { headers: headers })
        .then((response) => {
          this.$notify.success({
            title: "Успешно",
            message: response.status,
          });
          this.content = '';
          this.dialogVisible = false;
          loading.close();
        })
        .catch((err) => {
          console.log(err);
          loading.close();
          this.$notify.error({
            title: "Ошибка",
            message: err,
          });
        });
    }
  },
};
</script>

<style lang="scss"></style>