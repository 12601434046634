<template>
	<div
		v-loading.fullscreen.lock="fullscreenLoading"
		class="task-list-component"
	>
		<el-container direction="vertical">
			<div class="messages-header">
				<el-radio-group
					class="radio-group"
					v-model="selected"
					@change="radioChange"
				>
					<el-radio
						v-for="(option, index) in options"
						:key="index"
						:label="option.value"
					>
						{{ option.text }}
					</el-radio>
				</el-radio-group>

				<el-input
					class="search-message"
					v-model="searchtext"
					@input="updateListSearch"
					placeholder="Введите название задачи или вставьте ссылку на задачу"
					size="medium"
					clearable
				></el-input>
			</div>

			<vTaskCreate @updatelist="updateListAndOpenTask" />

			<div class="task-list">
				<div
					v-for="(task, index) in tasks"
					:key="index"
					class="task"
					@click="onClickTask(task)"
				>
					<span class="task-title">
						<i v-if="task.type == 'm'" class="el-icon-message"></i>
						<i v-else class="el-icon-tickets"></i> 
						{{ task.taskname }}
					</span>

					<i class="el-collapse-item__arrow el-icon-arrow-right"></i>
				</div>
			</div>

			<el-row
				type="flex"
				justify="center"
				class="row-pagination"
			>
				<el-pagination
					background
					layout="prev, pager, next"
					@current-change="pageChange"
					:current-page="currentPage"
					:page-count="rows"
				></el-pagination>
			</el-row>

			<vTaskView
				:propTask="currentTask"
				:activities="activities"
				@closeDialog="closeDialog"
				@deleteTask="deleteTask"
			></vTaskView>

			<el-dialog
				:visible.sync="isDialogTask"
				width="90%"
			>
				<div
					class="dialog-content"
					v-loading="dialogLoading"
				>
					<div class="dialog-field">
						<el-input
							v-model="dialogFieldVmodel"
							placeholder="Поиск"
							
						></el-input>

						<el-alert
							v-show="errorMessage !== null && errorMessage?.Text"
							type="error"
							title="Ошибка"
							show-icon
							:closable="false"
						>
						<!-- :description="errorMessage?.Text" -->

							<span style="font-size: 16px">
								{{ errorMessage?.Text }}
							</span>
						</el-alert>
					</div>

					<el-button
						class="dialog-button"
						@click="fetchTaskInfo"
					>
						Перейти
					</el-button>
				</div>
			</el-dialog>
		</el-container>
	</div>
</template>

<script>
import axios from "axios";
import { ADRESS } from "@/store/actions/auth";
import vTaskView from "./components/vTaskView.vue";
import vTaskCreate from "./components/vTaskCreate.vue";
import Fetch from "./mixins/Fetch";
import { handelerErrors } from "@/plugins/utils"
import { Notification } from 'element-ui';

export default {
	name: "TaskList",
	components: {
		vTaskView,
		vTaskCreate,
	},
	mixins: [Fetch],
	data() {
		return {
			activities: "",
			tasks: null,
			objecttask: null,
			matching: null,
			currentPage: 1,
			rows: 1,
			selected: "new",
			searchtext: "",
			options: [
				{ text: "Новые", value: "new" },
				{ text: "Несогласованные", value: "noreconcilation" },
				{ text: "Все", value: "all" },
			],
			fullscreenLoading: false,
			currentTask: null,

			isDialogTask: false,
			dialogFieldVmodel: "",
			dialogLoading: false,
			errorMessage: null
		};
	},
	methods: {
		async fetchTaskInfo() {
			try {
				console.clear()
				this.dialogLoading = true
				this.errorMessage = null

				if (!this.searchtext) {
					return
				}

				// e1cib/data/Задача.ЗадачаИсполнителя?ref=80dcf4ee0806c31111eeaeb1369ebdb0

				const resp = await axios({
					// url: "http://it-10/tr_crm/hs/crmapi/linksearch",
					url: ADRESS + "linksearch",
					method: "get",
					params: {
						link: this.searchtext
					},
				})

				const data = resp.data

				if (data.GUID !== undefined && data.type !== undefined) {
					this.currentTask = {
						id: data.GUID,
						type: data.type
					}
				}

				if (data.Comment !== undefined) {
					this.errorMessage = data
					Notification.error({
						title: "Ошибка",
						message: this.errorMessage.Text,
						duration: 0,
					})
				}
		
			} catch (e) {
				console.error(e)
				handelerErrors(e)
			} finally {
				this.dialogLoading = false
			}
		},
		fetchData() {
			console.log("fetch");
			this.activities = localStorage.getItem("user-token");
			this.firstGet();
		},
		updateListSearch() {
			var executed = false;

			if(this.searchtext.includes("Задача.ЗадачаИсполнителя") || this.searchtext.includes("Документ.TR_Сообщение") ){
				this.fetchTaskInfo()
			}

			if (this.searchtext.length > 3) {
				axios({
					method: "post",
					url: ADRESS + "getshortnewsfeed",
					params: {
						currentpage: this.currentPage,
						showmessage: this.selected,
						searchtext: this.searchtext,
						title: this.title,
					},
					headers: {
						Authorization: this.activities,
					},
				})
				.then((response) => {
					this.tasks = response.data.value.listoftasks;
					this.rows = parseInt(response.data.value.numberpages, 10);
					this.fullscreenLoading = false;
					executed = true;
				})
				.catch((err) => {
					this.fullscreenLoading = false;
					executed = true;
					handelerErrors(err);
				});

				setTimeout(() => {
					if (!executed) {
						this.fullscreenLoading = true;
					} else {
						this.fullscreenLoading = false;
					}
				}, 200);

				window.scrollTo(0, 0);
			}
		},
		updateList() {
			let executed = false;
			this.fullscreenLoading = true;

			axios({
				method: "post",
				url: ADRESS + "getshortnewsfeed",
				params: {
				currentpage: this.currentPage,
				showmessage: this.selected,
				searchtext: this.searchtext,
				title: this.title,
				},
				headers: {
					Authorization: this.activities,
				},
			})
				.then((response) => (
					(this.tasks = response.data.value.listoftasks),
					(this.rows = parseInt(response.data.value.numberpages, 10)),
					(this.fullscreenLoading = false),
					(executed = true)
				))
				.catch((err) => {
					this.fullscreenLoading = false;
					executed = true;
					handelerErrors(err);
				});

			setTimeout(() => {
				if (!executed) {
					this.fullscreenLoading = true;
				} else {
					this.fullscreenLoading = false;
				}
			}, 200);

			window.scrollTo(0, 0);
		},
		radioChange(selected) {
			this.selected = selected;
			this.currentPage = 1;
			this.updateList();
		},
		pageChange(page) {
			this.currentPage = page;
			this.updateList();
		},
		firstGet() {
			this.fullscreenLoading = true;
			var objecttask = {
				taskname: "",
				description: "",
				author: "",
				executor: "",
				dates: "",
				messagelist: [],
				allmessagelist: [],
			};
			var matching = {
				htmlstring: "",
				seen: true,
			};

			axios({
				method: "post",
				url: ADRESS + "getshortnewsfeed",
				params: {
				currentpage: this.currentPage,
				showmessage: this.selected,
				searchtext: this.searchtext,
				title: this.title,
				},
				headers: {
					Authorization: this.activities,
				},
			})
				.then((response) => {
					this.tasks = response.data.value.listoftasks;
					this.rows = parseInt(response.data.value.numberpages, 10);
					this.fullscreenLoading = false;
				})
				.catch((err) => {
					this.fullscreenLoading = false;
					handelerErrors(err);
				});

			this.objecttask = objecttask;
			this.matching = matching;
		},
		onClickTask(task) {
			this.currentTask = task;
		},
		closeDialog() {
			this.currentTask = null;
		},
		deleteTask(taskId) {
			this.tasks.splice(this.tasks.findIndex((task) => task.id === taskId));
		},
		updateListAndOpenTask(data) {
			this.selected = "all";
			this.firstGet();
		},
	},
};
</script>

<style lang="scss">
.el-notification__content {
	text-align: start !important;
}

.task-list-component {
	.messages-header {
		width: 100%;
		margin: 20px 0 10px;
		padding: 0 15px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		// justify-content: center;
		gap: 20px;

		@media (width <= 768px) {
			gap: 10px;
		}

		.radio-group {
			flex: 1 1 none;
		}

		.search-message {
			flex: 1 1 300px;
		}

		.follow-link {
			flex: 1 1 none;
		}
		// .el-input {
		// 	width: calc(100% - 320px);
		// }

		// .radio-group {
		// 	width: 320px;
		// 	display: flex;
		// 	align-items: center;

		// 	.el-radio {
		// 			margin-right: 20px;
		// 		}
		// 	}

		// 	.el-radio {
		// 		margin-right: 20px;
		// 	}

		// 	.el-radio__label {
		// 	padding-left: 5px;
		// 	}

		// 	@media (max-width: 550px) {
		// 		.el-input {
		// 			width: 100%;
		// 		}

		// 		.radio-group {
		// 			margin-bottom: 10px;
		// 		}
		// 	}
	}

	.task-list {
		margin-bottom: 10px;

		.task {
			display: flex;
			padding: 12px 10px;
			border-bottom: 1px solid #ebeef5;
			cursor: pointer;
		}

		.task-title {
			font-size: 16px;
			line-height: 20px;

			@media (max-width: 550px) {
				font-size: 13px;
				line-height: 16px;
			}

			.el-icon-tickets {
				color: #008d82;
				font-weight: 800;
			}

			.el-icon-message {
				color: #845ec2;
				font-weight: 800;
			}
		}
	}

	.el-collapse-item {
		padding: 10px 20px;
	}

	.search-message .el-input {
		width: 300px;
	}

	div.edit {
		padding: 5px;
		min-height: 22px;
		background: #fff;
		border-radius: 5px;
		border: 1px solid;
		margin: 10px 0;
	}

	div.edit:focus {
		background: #ffe;
		outline: 0;
		outline-offset: 0;
	}

	.row-pagination {
		margin: 20px 0;
	}
}

.dialog-content {
	display: flex;
	align-items: start;
	gap: 10px;
	flex-wrap: wrap;

	.dialog-field {
		flex: 1 1 300px;
	}	

	.dialog-button {
		flex: 1 1 none
	}
}
</style>