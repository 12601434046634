var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading.fullscreen.lock",value:(_vm.fullscreenLoading),expression:"fullscreenLoading",modifiers:{"fullscreen":true,"lock":true}}],staticClass:"list-of-offers"},[(_vm.offers !== null)?_c('div',[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.offers.filter(
        (data) =>
          data.nameCompany !== null &&
          (!_vm.search ||
            data.nameCompany.toLowerCase().includes(_vm.search.toLowerCase()) ||
            data.managerName.toLowerCase().includes(_vm.search.toLowerCase()) ||
            data.productName.toLowerCase().includes(_vm.search.toLowerCase()))
      ),"default-sort":{ prop: 'date', order: 'ascending' },"stripe":""}},[_c('el-table-column',{attrs:{"prop":"date","min-width":"120","sortable":"","sort-method":_vm.sortDate,"label":"Дата"}}),_c('el-table-column',{attrs:{"min-width":"200","label":"Компания"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{domProps:{"innerHTML":_vm._s(scope.row.nameCompany)}})]}}],null,false,2022565667)}),_c('el-table-column',{attrs:{"min-width":"90","prop":"brand","label":"Бренд"}}),_c('el-table-column',{attrs:{"min-width":"90","prop":"model","label":"Модель"}}),(_vm.userId)?_c('el-table-column',{attrs:{"min-width":"150","prop":"managerName","sortable":"","label":"Менеджер"}}):_vm._e(),_c('el-table-column',{attrs:{"fixed":"right","width":"90"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"size":"mini","icon":"el-icon-view","circle":""},on:{"click":function($event){return _vm.onClickView(scope)}}}),_c('el-button',{attrs:{"size":"mini","icon":"el-icon-edit","type":"success","circle":""},on:{"click":function($event){return _vm.onClickEdit(scope)}}})]}}],null,false,729546233)})],1),_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","current-page":_vm.currentPage,"page-count":_vm.pageCount},on:{"current-change":_vm.pageChange}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }