<template>
    <header class="header-component">
        <div class="header-component__left">
            <el-button class="icon-menu"
                size="small"
                :icon="iconOpen"
                @click="isMenuOpen = !isMenuOpen"
            ></el-button>

            <div class="logo">
                <img src="/assets/images/logo_trr.svg" />
            </div>

            <h1 class="title">
                {{ title }}
            </h1>
        </div>

        <div class="header-component__right">
            <strong class="profile-name">{{ userName }}</strong>

            <el-button class="button-exit"
                size="small"
                @click="logout"
            >
                Выйти
            </el-button>

            <el-button class="button-info"
                size="small"
                @click="isDialogInfo = !isDialogInfo"
                icon="el-icon-info"
                type="info"
            >
                Поддержка
            </el-button>
        </div>

        <el-drawer
            custom-class="header-menu"
            :visible.sync="isMenuOpen"
            :size="240"
            direction="ltr"
            :with-header="false"
        >
            <ul>
                <li v-for="(link, index) in links" :key="index">
                    <router-link
                        v-if="!isDiler || !link.isNotForDealer"
                        :to="link.href"
                        :icon="link.icon"
                        :class="{
                            'header-menu__active-link': $route.path === link.href
                        }"
                    >
                        <i
                            :class="link.icon"
                        ></i>

                        {{ link.text }}
                    </router-link>
                <!-- <router-link
                    v-else
                    :to="link.href"
                    :class="{ 'header-menu__active-link': link.isActive }"
                    ><i :class="link.icon"></i
                    ><el-badge value="new" class="item">{{
                    link.text
                    }}</el-badge></router-link
                > -->
                </li>
            </ul>
        </el-drawer>

        <el-dialog
            width="90%"
            :visible.sync="isDialogInfo"
            title="Информация"
        >
            <el-row type="flex" align="middle" style="flex-wrap: wrap; gap: 10px;">
                <span style="word-break: keep-all;"> 
                    При возникновении вопросов по работе программы просим обращаться по электронной почте:
                </span>

                <el-link
                    type="primary"
                    href="mailto:dealer_support@tr-lift.ru"
                >
                    dealer_support@tr-lift.ru
                </el-link>

                <el-button
                    size="small"
                    icon="el-icon-copy-document"
                    @click="copyText"
                >
                    Скопировать почтовый адрес
                </el-button>
            </el-row>
        </el-dialog>
    </header>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { AUTH_LOGOUT, USER_REQUEST } from "@/store/actions/auth";
import Fetch from "../mixins/Fetch";

export default {
    name: "vHeader",
    mixins: [Fetch],
    data() {
        return {
            token: "",
            currentPage: "",
            isDialogInfo: false,
            links: [
                {
                    href: "/",
                    icon: "el-icon-circle-plus-outline",
                    text: "Создать КП",
                    isActive: false,
                    isNew: false,
                    isNotForDealer: true,
                },
                {
                    href: "/all-offers/",
                    icon: "el-icon-s-management",
                    text: "Мои КП",
                    isActive: false,
                    isNew: false,
                    isNotForDealer: true,
                },
                {
                    href: "/task-list/",
                    icon: "el-icon-message",
                    text: "Лента сообщений",
                    isActive: false,
                    isNew: false,
                    isNotForDealer: true,
                },
                {
                    href: "/check-firm/",
                    icon: "el-icon-search",
                    text: "Проверка контрагента",
                    isActive: false,
                    isNew: false,
                    isNotForDealer: false,
                },
                {
                    href: "/company-requests/",
                    icon: "el-icon-tickets",
                    text: "Запросы на закрепления",
                    isActive: false,
                    isNew: false,
                    isNotForDealer: false,
                },
                {
                    href: "/all-clients/",
                    icon: "el-icon-notebook-2",
                    text: "Контрагенты",
                    isActive: false,
                    isNew: false,
                    isNotForDealer: false,
                },
                {
                    href: "/technics/",
                    icon: "el-icon-s-order",
                    text: "Техника",
                    isActive: false,
                    isNew: true,
                    isNotForDealer: false,
                },
                {
                    href: "/incoming-calls/",
                    icon: "el-icon-s-promotion",
                    text: "Входящие обращения",
                    isActive: false,
                    isNew: false,
                    isNotForDealer: true,
                },
                {
                    href: "/agreements/",
                    icon: "el-icon-circle-check",
                    text: "Согласования",
                    isActive: false,
                    isNew: false,
                    isNotForDealer: true,
                },
                {
                    href: "/vcf/",
                    icon: "el-icon-user",
                    text: "QR контакт",
                    isActive: false,
                    isNew: true,
                    isNotForDealer: true,
                },
                {
                    href: "/price-list/",
                    icon: "el-icon-tickets",
                    text: "Прайс лист",
                    isActive: false,
                    isNew: true,
                    isNotForDealer: false,
                },
            ],
            isMenuOpen: false,
            userName: "",
            title: null,
        };
    },
    created() {
        this.$watch(() => this.$route.params, () => {
            this.isMenuOpen = false;
        }, { immediate: true })
    },
    watch: {
        $route(to, from) {
            this.changeTitle();
        },
    },
    computed: {
        ...mapGetters([
            "getProfile",
            "isAuthenticated",
            "isProfileLoaded",
            "isDiler",
        ]),
        ...mapState({
            authLoading: (state) => state.auth.status === "loading",
            name: (state) => `${state.user.profile.name}`,
        }),
        iconOpen() {
            return this.isMenuOpen ? "el-icon-close" : "el-icon-menu";
        },
    },
    methods: {
        fetchData() {
            this.token = localStorage.getItem("user-token");
            this.changeTitle();
            this.userName = localStorage.getItem("user-fio");
            if (this.userName !== null) {
                this.userName.split(" ")[0];
            }
        },
        logout() {
            this.$store.dispatch("AUTH_LOGOUT")
        },
        changeTitle() {
            this.links = this.links.map((item) => {
                if (window.location.pathname == item.href) {
                    item.isActive = true;
                    document.title = item.text;
                    this.title = item.text;

                    return item;
                }
                else {
                    return item;
                }
            });
        },
        async copyText() {
            const text = 'dealer_support@tr-lift.ru'

            const unsecuredCopyToClipboard = () => {
                const textArea = document.createElement("textarea")
                textArea.value = text
                document.body.appendChild(textArea)
                textArea.focus()
                textArea.select()

                try{
                    document.execCommand('copy')
                }
                catch (e) {
                    console.error('Unable to copy to clipboard', e)
                }

                document.body.removeChild(textArea)
            };

            if (window.isSecureContext && navigator.clipboard) {
                navigator.clipboard.writeText(text);
            }
            else {
                unsecuredCopyToClipboard(text);
            }

            this.$notify.success({
                title: "Успешно скопировано!",
            })
        }
    }
};
</script>

<style lang="scss">
    $active-color: #409eff;
    $fill-color: rgba(128, 128, 128, 0.063);

    .header-component {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        position: relative;

        @media (width <= 1024px) {
            padding: 10px;
        }

        @media (width <= 475px) {
            flex-wrap: wrap;
            justify-content: start;
            gap: 10px;
        }

        .header-component__left {
            display: flex;
            align-items: center;
            gap: 20px;

            @media (width <= 1024px) {
                gap: 10px;
            }

            .logo {
                width: 230px;
                display: flex;
                align-items: center;

                img {
                    width: 100%;
                    object-fit: contain;
                }

                @media (width <= 680px) {
                    display: none;
                }
            }

            .title {
                font-size: 16px;
                border-left: 1px solid #000000;
                padding-left: 20px;
                white-space: nowrap;

                @media (width <= 1024px) {
                    font-size: 14px;
                    padding-left: 10px;
                    
                }
            }
        }

        .header-component__right {
            display: flex;
            gap: 20px;
            align-items: center;

            @media (width <= 1024px) {
                gap: 10px;
            }

            @media (width <= 475px) {
                justify-content: space-between;
                width: 100%;
                flex-direction: row-reverse;
            }

            .profile-name {
                @media (width <= 1024px) {
                    font-size: 14px;
                }

                @media (width <= 900px) {
                    display: none;
                }
            }

            // .button-exit {

            // }

            .button-info {
                margin: 0;
            }
        }

        .header-menu {
            ul {
                list-style-type: none;

                li {
                    display: block;

                    a {
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        font-size: 14px;
                        color: #000;
                        padding: 20px 10px;
                        text-decoration: none;

                        &:hover {
                            color: $active-color;
                            background: $fill-color;
                        }
                    }
                }
            }

            .header-menu__active-link {
                color: $active-color;
                background: $fill-color;
            }
        }
    }
</style>