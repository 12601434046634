<template>
  <div>
    <v-header v-if="isLogin" />
    <router-view />
  </div>
</template>

<script>
import vHeader from "./components/vHeader.vue";
export default {
  name: "App",
  components: { vHeader },
  data() {
    return {};
  },
  computed: {
    isLogin() {
      return this.$route.path !== "/login";
    },
  },
};
</script>

<style scoped lang="scss">
</style>
