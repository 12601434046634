import store from "@/store/index"
import { Notification } from 'element-ui';

export function handelerErrors(e) {
    if (e.response.status == 403) {
        store.dispatch("AUTH_LOGOUT");
        Notification.warning({
            title: "Уведомление",
            message: "Авторизуйтесь заново",
        });
        console.log(e);
    } else {
        console.log(e);
        Notification.error({
            title: "Ошибка",
            message: e.response.data ? e.response.data : e,
        });
    }
}