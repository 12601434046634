<template>
  <div class="task-create">
    <el-row class="task-create__buttons">
      <el-button size="mini" @click="openDialog(true)">Создать задачу</el-button>
      <el-button size="mini" @click="openDialog(false)">Создать сообщение</el-button>
    </el-row>
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisibleOuter" @closed="onCloseDialog" width="96%"
      :close-on-click-modal="false">
      <div class="task-create__row">
        <p>Тема:</p>
        <el-input :class="task.title.length < 5 ? 'red-border' : ''" size="small" v-model="task.title" />
      </div>
      <div v-if="isTask" class="task-create__row">
        <p>Тип задачи:</p>
        <v-select v-model="task.type" :appendToBody="true" label="name" :options="taskTypes"
          placeholder="Выберете тип"></v-select>
      </div>
      <div v-if="isTask" class="task-create__row">
        <p>Исполнитель:</p>
        <v-select v-model="task.user" :class="task.user === null ? 'red-border' : ''" :appendToBody="true" label="name"
          :options="users" placeholder="Выберете сотрудника"></v-select>
      </div>
      <el-collapse>
        <el-collapse-item :title="isTask ? 'Наблюдатели' : 'Получатели'">
          <v-select v-if="groups.length > 0" v-model="currentGroup" class="mb10" :appendToBody="true" label="text"
            :options="groups" @option:selected="onSelectGroup" placeholder="Добавление из группы"></v-select>
          <v-select v-model="task.watcher" :appendToBody="true" label="name" :options="users"
            @option:selected="onSelectWatcher" placeholder="Выберете сотрудника"></v-select>
          <div class="task-view__watcher" v-for="(user, index) in task.watchers" :key="index">
            {{ user.name }}
            <el-button size="mini" type="danger" icon="el-icon-delete-solid" @click="deleteWatcher(user)" />
          </div>
        </el-collapse-item>
        <el-collapse-item v-if="isTask" title="Добавить согласующего">
          <v-select v-model="task.approvaler" :appendToBody="true" label="name" :options="users"
            placeholder="Выберете согласующего"></v-select>
        </el-collapse-item>
        <el-collapse-item v-if="isTask" title="Сроки:">
          <div class="task-create__row">
            <el-date-picker v-model="task.start" type="datetime" format="dd.MM.yyyy HH:mm" size="small"
              value-format="yyyyMMddHHmmss" placeholder="Начало">
            </el-date-picker>
            <el-date-picker v-model="task.end" type="datetime" format="dd.MM.yyyy HH:mm" size="small"
              value-format="yyyyMMddHHmmss" placeholder="Завершение">
            </el-date-picker>
          </div>
        </el-collapse-item>
        <el-collapse-item v-if="isTask" title="Контроль:">
          <div class="task-create__row task-create__row-controler">
            <v-select v-model="task.controler" :appendToBody="true" label="name" :options="users"
              placeholder="На контроле"></v-select>
            <el-date-picker v-model="task.term" type="date" format="dd.MM.yyyy" size="small" value-format="yyyyMMdd"
              placeholder="Срок">
            </el-date-picker>
          </div>
        </el-collapse-item>
      </el-collapse>
      <div v-if="!isTask" class="task-create__row">
        <p>Канал:</p>
        <v-select v-model="currentChannel" :appendToBody="true" label="name" :options="channels"
          @option:selected="onSelectChannel" placeholder="Выберете канал"></v-select>
        <div class="task-create__channels" v-for="(item, index) in task.channels" :key="index">
          {{ item.name }}
          <el-button size="mini" type="danger" icon="el-icon-delete-solid" @click="deleteChannel(item)" />
        </div>
      </div>
      <div v-if="isTask" class="task-create__row">
        <v-select v-model="task.importance" :appendToBody="true" label="name" :options="importanceTypes"
          placeholder="Важность"></v-select>
      </div>
      <div class="task-create__row">
        <el-input v-model="task.description" type="textarea" :class="task.description.length < 5 ? 'red-border' : ''"
          size="small" rows="6" resize="none" placeholder="Введите описание" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button :disabled="!validateForm" type="primary" @click="createTask">Отправить</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import { ADRESS } from "@/store/actions/auth";

export default {
  data: () => ({
    activities: "",
    dialogTitle: "",
    dialogVisibleOuter: false,
    task: {
      title: "",
      type: null,
      user: null,
      watcher: null,
      watchers: [],
      approvaler: null,
      start: "",
      end: "",
      controler: null,
      term: "",
      importance: null,
      description: "",
      channels: [],
    },
    isTask: null,
    users: [],
    taskTypes: [],
    importanceTypes: ["Высокая", "Обычная", "Низкая"],
    channels: [],
    groups: [],
    currentGroup: null,
    currentChannel: null,
  }),
  mounted() {
    this.activities = localStorage.getItem("user-token");
    this.getTaskTypes();
    this.getListUsers();
    this.getListChannels();
    this.getListGroups();
  },
  computed: {
    validateForm() {
      return (
        (this.task.title.length > 4 &&
          this.task.description.length > 4 &&
          this.task.user !== null) ||
        (this.task.title.length > 4 &&
          this.task.description.length > 4 &&
          (this.task.watchers.length > 0 || this.task.channels.length > 0))
      );
    },
  },
  methods: {
    onCloseDialog() {
      this.isTask = null;
      this.dialogTitle = "";
      this.task = {
        title: "",
        type: null,
        user: null,
        watcher: null,
        watchers: [],
        approvaler: null,
        start: "",
        end: "",
        controler: null,
        term: "",
        importance: null,
        description: "",
        channels: [],
      };
      this.currentGroup = null;
      this.currentChannel = null;
    },
    openDialog(boolean) {
      this.isTask = boolean;
      this.dialogVisibleOuter = true;
      this.dialogTitle = boolean ? "Создать задачу" : "Создать сообщение";
    },
    getTaskTypes() {
      axios
        .get(ADRESS + "get-types-task")
        .then((response) => {
          console.log(response.data);
          this.taskTypes = response.data === "" ? [] : response.data;
        })
        .catch((err) => {
          console.log(err);
          this.$notify.error({
            title: "Ошибка",
            message: err,
          });
        });
    },
    getListUsers() {
      axios({
        method: "get",
        url: ADRESS + "getlistusers",
        headers: { Authorization: this.activities },
      }).then((response) => {
        this.users = response.data.value.listusers.reduce((o, i) => {
          if (!o.find((v) => v.id == i.id)) {
            o.push(i);
          }
          return o;
        }, []);
      });
    },
    getListChannels() {
      axios({
        method: "get",
        url: ADRESS + "getlistchannels",
        headers: { Authorization: this.activities },
      }).then((response) => {
        this.channels = response.data === "" ? [] : response.data;
      });
    },
    getListGroups() {
      axios({
        method: "get",
        url: ADRESS + "getmygroup",
        headers: { Authorization: this.activities },
      }).then((response) => {
        this.groups = response.data === "" ? [] : response.data;
      });
    },
    deleteWatcher(delUser) {
      this.task.watchers = this.task.watchers.filter(
        (user) => user.id !== delUser.id
      );
      this.task.watcher = null;
      this.currentGroup = null;
    },
    onSelectWatcher() {
      this.task.watchers.push(this.task.watcher);
      this.task.watcher = null;
    },
    onSelectGroup() {
      // this.task.watchers = this.task.watchers.push(...this.currentGroup.list);
      this.task.watchers = this.currentGroup.list;
    },
    onSelectChannel() {
      this.task.channels.push(this.currentChannel);
      this.currentChannel = null;
    },
    deleteChannel(delChannel) {
      this.task.channels = this.task.channels.filter(
        (item) => item.id !== delChannel.id
      );
      this.currentChannel = null;
    },
    createTask() {
      const loading = this.$loading({
        lock: true,
      });
      axios({
        method: "post",
        url: ADRESS + "createtask",
        data: { ...this.task, isTask: this.isTask },
        params: { taskid: this.task.id },
        headers: {
          Authorization: this.activities,
        },
      })
        .then((response) => {
          this.$notify.success({
            title: "Успешно",
            message: response.status,
          });
          this.dialogVisibleOuter = false;
          loading.close();
          this.$emit("updatelist", response.data);
        })
        .catch((err) => {
          console.log(err);
          loading.close();
          this.taskMatching.reconciling = null;
          this.$notify.error({
            title: "Ошибка",
            message: err,
          });
        });
    },
  },
};
</script>
<style lang="scss">
.task-create {
  .el-dialog__body {
    padding: 0px 20px 10px;
  }

  .task-create__buttons {
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    padding: 0 15px;

    @media (max-width: 550px) {
      padding: 0;
      justify-content: center;
    }
  }

  .el-collapse {
    border-top: none;

    .el-collapse-item {
      padding: 0;
    }

    .el-collapse-item__header {
      font-weight: bold;
    }
  }

  .task-create__row-controler {
    .v-select {
      margin-bottom: 10px;
    }
  }

  .el-dialog__footer {
    text-align: left;
  }
}

body .vs__dropdown-menu {
  max-height: 250px;
  z-index: 1000000;
}

.mb10 {
  margin-bottom: 10px;
}
</style>