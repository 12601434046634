<template>
  <div v-loading.fullscreen.lock="fullscreenLoading" class="list-calls">
    <div class="list-calls-container" v-if="calls !== null">
      <el-row type="flex" class="list-calls__search">
        <el-input v-model="search" size="mini" placeholder="Поиск по коммерческим предложениям" />
        <el-button size="mini" type="primary" icon="el-icon-search"></el-button>
      </el-row>
      <el-table class="list-calls__table" @row-click="onClickInCall" :row-class-name="classForRow" :data="
        calls.filter(
          (data) =>
            data['КонтактноеЛицо'] !== null &&
            (!search ||
              data['КонтактноеЛицо']
                .toLowerCase()
                .includes(search.toLowerCase()) ||
              data['НаправлениеОбращения']['name']
                .toLowerCase()
                .includes(search.toLowerCase()) ||
              data['Город'].toLowerCase().includes(search.toLowerCase()))
        )
      " :default-sort="{ prop: 'date', order: 'descending' }" stripe style="width: 100%">
        <el-table-column prop="date" min-width="120" sortable :sort-method="sortDate" label="Дата">
        </el-table-column>
        <el-table-column label="Контактное лицо" min-width="100">
          <!-- eslint-disable-next-line -->
          <template slot-scope="scope">
            <span v-if="scope.row['Контрагент'] !== null">{{
              scope.row["Контрагент"]["Наименование"]
            }}</span>
            <span v-else>{{ scope.row["КонтактноеЛицо"] }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Направление обращения" min-width="110">
          <!-- eslint-disable-next-line -->
          <template slot-scope="scope">
            <span v-if="scope.row['НаправлениеОбращения'] !== null">{{
              scope.row["НаправлениеОбращения"]["name"]
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Город">
          <!-- eslint-disable-next-line -->
          <template slot-scope="scope">
            <span>{{ scope.row["Город"] }}</span>
          </template>
        </el-table-column>
      </el-table>
      <vCallView :propCall="currentCall" :activities="activities" :lists="lists" @closeDialog="currentCall = null"
        @onRedirect="removeCall" />
    </div>
  </div>
</template>


<script>
import axios from "axios";
import vCallView from "./components/vCallView.vue";
import moment from "moment";
import { ADRESS } from "@/store/actions/auth";
import Fetch from "./mixins/Fetch";
import { handelerErrors } from "@/plugins/utils"

export default {
  name: "IncomingCalls",
  components: {
    vCallView,
  },
  mixins: [Fetch],
  data() {
    return {
      calls: null,
      search: "",
      fullscreenLoading: false,
      activities: null,
      userId: "",
      isDialogShow: false,
      currentCall: null,
      lists: null,
    };
  },
  methods: {
    fetchData() {
      console.log("fetch");
      this.activities = localStorage.getItem("user-token");
      this.getListCalls();
    },
    sortDate(a, b) {
      return (
        moment(a, "DD.MM.YYYY HH:mm").unix() -
        moment(b, "DD.MM.YYYY HH:mm").unix()
      );
    },
    convertDate(date) {
      return moment(date, "DD.MM.YYYY HH:mm").format("DD.MM.YYYY HH:mm");
    },
    getListCalls() {
      this.fullscreenLoading = true;
      axios
        .get(`${ADRESS}getlistappeals`, {
          headers: { Authorization: this.activities },
        })
        .then((response) => {
          this.calls = response.data["Обращения"];
          this.calls = this.calls.map((call) => {
            call.date = this.convertDate(call["ДатаДляСортировки"]);
            return call;
          });
          this.lists = response.data;
          delete this.lists["Обращения"];
          this.fullscreenLoading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.fullscreenLoading = false;
          handelerErrors(err);
        });
    },
    onClickInCall(row) {
      console.log(row);
      this.currentCall = row;
    },
    classForRow(row) {
      switch (row.row["Статус"]) {
        case "Просрочено":
          return "calls-row__expired";
        default:
          return "calls-row__normal";
      }
    },
    removeCall(uik) {
      this.calls = this.calls.filter((item) => item["УИК"] !== uik);
    },
  },
};
</script>

<style lang="scss">
.list-calls-container {
  margin-top: 15px;
}

.list-calls {
  .list-calls__table {
    color: #000000;
    font-size: 12px;

    tr {
      cursor: pointer;

      span {
        overflow: hidden;
        word-spacing: nowrap;
      }
    }

    tr.calls-row__expired {
      background-color: #f58e8e;

      td {
        background-color: #f58e8e;
      }
    }
  }
}
</style>
