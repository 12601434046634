<template>
  <div v-loading.fullscreen.lock="fullscreenLoading" class="v-card-page">
    <canvas id="canvas" ref="canvas"></canvas>
  </div>
</template>


<script>
import axios from "axios";
import moment from "moment";
import { ADRESS } from "@/store/actions/auth";
import Fetch from "./mixins/Fetch";
import { mapGetters } from "vuex";
import { handelerErrors } from "@/plugins/utils"
import qrcode from "qrcode"


export default {
  name: "Technics",
  components: {},
  mixins: [Fetch],
  data() {
    return {
      fullscreenLoading: false,
      activities: null,
      userId: "",
      firstName: "",
      middleName: "",
      lastName: "",
      phone: "",
      email: ""
    };
  },
  computed: {
    ...mapGetters(["isDiler"]),
  },
  methods: {
    fetchData() {
      this.activities = localStorage.getItem("user-token");
      let fio = localStorage.getItem("user-fio").split(" ")
      if ((fio.length > 1) && (fio.length === 3)) {
        this.firstName = fio[1];
        this.middleName = fio[2];
        this.lastName = fio[0];
      } else {
        this.firstName = fio[1];
        this.middleName = "";
        this.lastName = fio[0];
      }
      this.phone = localStorage.getItem("user-phone");
      this.email = localStorage.getItem("user-mail");
      const url = `https://crm.liftnet.ru/vcf/?firstName=${this.firstName}&middleName=${this.middleName}&lastName=${this.lastName}&email=${this.email}&phone=${this.phone}`;
      this.$nextTick(() => {
        qrcode.toCanvas(this.$refs.canvas, url, { width: 320 }, (error) => {
          if (error) console.error(error)
          console.log('success!');
        })
      })
    },
  },
};
</script>

<style lang="scss">
.v-card-page {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
