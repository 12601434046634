import { render, staticRenderFns } from "./vHeader.vue?vue&type=template&id=31703850&"
import script from "./vHeader.vue?vue&type=script&lang=js&"
export * from "./vHeader.vue?vue&type=script&lang=js&"
import style0 from "./vHeader.vue?vue&type=style&index=0&id=31703850&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports